import cookies from '@/cookies'
import AppApi from '@/api/AppApi'
import CategoryApi from '@/api/CategoryApi'
import ConversationsApi from '@/api/conversations/ConversationsApi'
import utils from '@/utils'

const directDeals = ['Alrincon', 'Tubetraffic', 'YourAmateurPorn']
const adsNetworks = ['Exoclick', 'TrafficJunky', 'Twinred', 'Trafficstars']

const actionsTypes = {
  FetchMasterData: 'fetch_master_data',
  UpdateScreenSize: 'update_screen_size',
  UpdateSearching: 'update_searching',
  UpdateHasNotifications: 'update_has_notifications',
  UpdateHasMessages: 'update_has_messages',
  UpdateReferral: 'update_referral',
  UpdateAffiliate: 'update_affiliate',
  UpdateCookies: 'update_cookies',
  CreateCategory: 'create_category',
  UpdateCategory: 'update_category',
  DeleteCategory: 'delete_category',
  ShowNotification: 'show_notification',
  SetNotification: 'set_notification',
  ShowNotificationsCall: 'show_notification_call',
  SetDataCall: 'set_data_call',
  SetJoinVideoCall: 'set_join_video_call'
}

const state = {
  isMobile: false,
  isSmallScreen: false,
  isMediumScreen: false,
  isLargeScreen: false,
  isSmallScreenOrGreater: false,
  isMediumScreenOrGreater: false,
  isSearching: false,
  screenWidth: 0,
  screenHeight: 0,
  hasNotifications: false,
  hasMessages: false,
  affiliate: cookies.get('affiliate') || null,
  affiliateSource: cookies.get('affiliateSource') || null,
  referral: null,
  showCookies: !cookies.get('allow-cookies-v2'),
  billing: {},
  tags: [],
  categories: [],
  showNewNotification: false,
  showNotificationsCall: false,
  joinVideoCall: false,
  dataCall: {
    videochatId: 0,
    username: '',
    price: 0,
    currency: '',
    avatar: ''
  },
  notification: null,
  countUnreadMessages: '0'
}

const mutations = {
  reset: state => {
    state.isSearching = false
  },

  screen_size: (state, dimensions) => {
    state.screenWidth = dimensions.width
    state.screenHeight = dimensions.height
    state.isLargeScreen = dimensions.width >= 1264
    state.isMediumScreen = dimensions.width >= 960 && dimensions.width < 1264
    state.isSmallScreen = dimensions.width >= 768 && dimensions.width < 960
    state.isMobile = dimensions.width < 763

    state.isMediumScreenOrGreater = dimensions.width >= 960
    state.isSmallScreenOrGreater = dimensions.width >= 768
  },
  searching_state: (state, isSeaching) => {
    state.isSearching = isSeaching
  },

  has_notifications: (state, hasNew) => {
    state.hasNotifications = hasNew
  },

  has_messages: (state, hasNew) => {
    state.hasMessages = hasNew
  },
  count_unread_messages: (state, count) => {
    state.countUnreadMessages = count
  },

  set_referral: (state, referral) => {
    state.referral = referral
  },

  set_affiliate: (state, {affiliate, affiliateSource}) => {
    state.affiliate = affiliate
    state.affiliateSource = affiliateSource
  },

  set_cookies: (state, show) => {
    state.showCookies = !show
  },

  set_billing: (state, billing) => {
    state.billing = billing
  },
  set_tags: (state, tags) => {
    state.tags = tags
  },
  set_categories: (state, categories) => {
    categories.forEach(category => {
      category.de = utils.capitalize(category.de)
      category.en = utils.capitalize(category.en)
      category.es = utils.capitalize(category.es)
      category.pt = utils.capitalize(category.pt)
      category.fr = utils.capitalize(category.fr)
      category.it = utils.capitalize(category.it)
    })
    state.categories = categories
  },
  set_category: (state, category) => {
    const idx = state.categories.findIndex(c => c.id === category.id)
    if (idx !== -1) {
      state.categories.splice(idx, 1, category)
    } else {
      state.categories.push(category)
    }
  },
  remove_category: (state, category) => {
    const idx = state.categories.findIndex(c => c.id === category.id)
    if (idx !== -1) {
      state.categories.splice(idx, 1)
    }
  },
  show_notification: (state, notification) => {
    state.showNewNotification = notification
  },
  show_notification_call: (state, call) => {
    state.showNotificationsCall = call
  },
  set_data_call: (state, dataCall) => {
    state.dataCall = dataCall
  },
  set_notification: (state, notification) => {
    state.notification = notification
  },
  join_video_call: (state, joinCall) => {
    state.joinVideoCall = joinCall
  }
}

const actions = {
  [actionsTypes.FetchMasterData]: async context => {
    const {data, error} = await AppApi.masterData()
    if (error) {
      return data
    }
    context.commit('set_billing', data.billing)
    context.commit('set_tags', data.tags)
    context.commit('set_categories', data.categories)
    return {data}
  },
  [actionsTypes.UpdateScreenSize]: (context, dimensions) => {
    context.commit('screen_size', dimensions)
  },

  [actionsTypes.UpdateSearching]: (context, searching) => {
    context.commit('searching_state', searching)
  },

  [actionsTypes.UpdateHasNotifications]: (context, hasNew) => {
    context.commit('has_notifications', hasNew)
  },

  [actionsTypes.UpdateHasMessages]: async (context, hasNew) => {
    const {data, error} = await ConversationsApi.countUnreadMessages()
    if (!error) {
      const count = data.countUnreadMessages
      let value = `${count}`
      if (count > 99) {
        value = '+99'
      } else if (count === 0) {
        value = ''
      }
      context.commit('count_unread_messages', value)
    }
    context.commit('has_messages', hasNew)
  },

  [actionsTypes.UpdateReferral]: (context, ref) => {
    const internalReferrals = [979172, 979173, 880127]
    if (internalReferrals.indexOf(ref) > -1) {
      cookies.expiration('referral', ref, 720)
    } else {
      cookies.expiration('referral', ref, 3)
    }
    context.commit('set_referral', ref)
  },

  [actionsTypes.UpdateAffiliate]: (context, payload) => {
    if (cookies.get('affiliate')) {
      return
    }
    context.commit('set_affiliate', payload)
    let time = 24 * 60 * 60 // one day
    if (directDeals.includes(payload.affiliate) || adsNetworks.includes(payload.affiliate)) {
      time = 7 * 24 * 60 * 60
    }
    cookies.set('affiliate', payload.affiliate, {time})
    cookies.set('affiliateSource', payload.affiliateSource, {time})
  },

  [actionsTypes.UpdateCookies]: (context, show) => {
    context.commit('set_cookies', show)
  },
  [actionsTypes.ShowNotification]: (context, notification) => {
    context.commit('show_notification', notification)
  },

  [actionsTypes.SetNotification]: (context, notification) => {
    context.commit('set_notification', notification)
  },
  [actionsTypes.ShowNotificationsCall]: (context, call) => {
    context.commit('show_notification_call', call)
  },
  [actionsTypes.SetDataCall]: (context, dataCall) => {
    context.commit('set_data_call', dataCall)
  },
  [actionsTypes.SetJoinVideoCall]: (context, joinCall) => {
    context.commit('join_video_call', joinCall)
  },
  [actionsTypes.CreateCategory]: async (context, category) => {
    const {data, error} = await CategoryApi.create(category)
    if (!error) {
      context.commit('set_category', data)
    }
    return data
  },

  [actionsTypes.UpdateCategory]: async (context, category) => {
    const {data, error} = await CategoryApi.update(category)
    if (!error) {
      context.commit('set_category', data)
    }
    return data
  },

  [actionsTypes.DeleteCategory]: async (context, category) => {
    const {data, error} = await CategoryApi.delete(category.id)
    if (!error) {
      context.commit('remove_category', category)
    }
    return data
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'application',
  namespaced: true,
  state,
  mutations,
  actions
}
