import i18n from '@/i18n'
import {isValid} from 'better-dni'
import config from "@/project";
import store from '@/store'

const tlds = [
  'com', 'net', 'es', 'info', 'org', 'xyz', 'io', 'me', 'top', 'in', 'eu', 'ai', 'online', 'us', 'biz', 'tv', 'co', 'tk', 'uk', 'fans'
].join('|')

const wordsBanned = [
  'alcohol', 'liquor', 'licor', 'beer', 'cerveza',
  'asphyxia', 'asphyxiate', 'asphyxiation', 'asfixia', 'asfixiar',
  'beastiality', 'bestiality', 'bestialidad',
  'beer',
  'bled', 'bleed', 'bleeding', 'blood', 'bloody', 'bl00d', 'blo0d', 'bl0od', 'blod', 'bl0d',
  'sangrado', 'sangrando', 'sangre', 'sangrre', 'sangree', 'sang',
  'alcohol', 'alcohol',
  'brutality', 'brutalidad',
  'brutalization', 'brutalización',
  'child', 'children', 'niño', 'niña', 'niños', 'niñas',
  'chloroform', 'cloroformo',
  'choking',
  'compresa', 'compress',
  'drug', 'drugs', 'drugged', 'droga', 'drogas', 'drogado', 'drogados', 'drogada', 'drogadas',
  'drunk', 'drunken', 'borracho', 'borracha',
  'drink',
  'force', 'forced', 'forces', 'forzado', 'forzar',
  'forcing', 'forzando',
  'gun', 'pistola',
  'herida', 'heridas',
  'horror',
  'hypnosis', 'hipnosis',
  'hypnotize', 'hypnotized', 'hipnotizar', 'hipnotizado',
  'incapacited', 'incapacite', 'incapacitation', 'incapacidad', 'incapacitado', 'incapacitacion',
  'incest', 'incesto', 'incest0', 'cesto-in', 'incest0', 'incestoo',
  'infant', 'infante',
  'intoxicate', 'intoxicar',
  'intoxicated', 'intoxicado',
  'intoxication', 'intoxicación',
  'kik', 'k1k',
  'kids', 'Lolita', 'Pedo', 'Peta', 'Peto', 'Pre-teen', 'Pedophile', 'Underage',
  'kill',
  'knife', 'cuchillo',
  'menstrual', 'menstrua',
  'menstruation', 'menstruatio', 'menstruacion', 'menstruaci0n', 'menstruating', 'menstruofilia',
  'molest', 'molestation', 'molestar',
  'murder', 'murderer', 'asesinato', 'asesino', 'asesina',
  'mutilate', 'mutilated', 'mutilar',
  'mutilation', 'mutilación', 'mutilati0n', 'mutilat*on', 'mutilacion',
  'necro',
  'necrophilia', 'necrofilia',
  'passed out', 'muerto',
  'pedophilia', 'pedofilia', 'pedofilo', 'pedofila',
  'period', 'periodo', 'per*od', 'peri0d', 'periodo', 'per*odo', 'peri0d', 'peri0do', 'peri0d0',
  'rape', 'raped', 'raping', 'violacion', 'violar', 'violada', 'violado', 'violando',
  'regla',
  'sedate', 'sedation', 'sedated', 'sedar', 'sedación', 'sedado', 'sedada',
  'sleep', 'slumber', 'be asleep', 'dormido', 'dormida',
  'smother',
  'tampax', 'tampon', 'tamp0n',
  'trance',
  'tentacle', 'tentacles', 'tentáculo', 'tentáculos',
  'telegram', 't3legram', 'te1egram', 'tel3gram', 'telegr4m', 't31egram', 't3l3gram', 't3legr4m', 'te13gram', 'te1egr4m', 'tel3gr4m', 't313gram', 't31egr4m', 'te13gr4m', 't313gr4m',
  'unconscious', 'inconsciente',
  'underage', 'menor de edad',
  'unwilling', 'reacio', 'reacia',
  'violate', 'violacion', 'violación', 'violated', 'violation', 'violati0n', 'violat*0n', 'violaci0n', 'violac*on', 'violac*0n',
  'weapon', 'arma',
  'wine',
  'wounds',
  'menofilia',
  'procaina',
  'hund',
  'onlyfans', '0nlyfans', 'onlyf4ns', 'onlyfan5', '0nlyf4ns', '0nlyfan5', 'onlyf4n5', '0nlyf4n5',
  '#onlyfans', '#0nlyfans', '#onlyf4ns', '#onlyfan5', '#0nlyf4ns', '#0nlyfan5', '#onlyf4n5', '#0nlyf4n5',
  'onlyfan', '0nlyfan', 'onlyf4n', '0nlyf4n',
  '#onlyfan', '#0nlyfan', '#onlyf4n', '#0nlyf4n',
  'fansly', 'fans.ly', 'f4nsly', 'f4ns.ly',
  '#fansly', '#fans.ly', '#f4nsly', '#f4ns.ly',
  'fancentro', 'f4ncentro', 'fanc3ntro', 'fancentr0', 'f4nc3ntro', 'f4ncentr0', 'fanc3ntr0',
  '#fancentro', '#f4ncentro', '#fanc3ntro', '#fancentr0', '#f4nc3ntro', '#f4ncentr0', '#fanc3ntr0',
  'celeb.tv', 'c3leb.tv', 'cel3b.tv', 'c3l3b.tv',
  '#celeb.tv', '#c3leb.tv', '#cel3b.tv', '#c3l3b.tv',
  'loyalfans', 'loy4lfans', 'loyalf4ns', 'loy4lf4ns', 'l0yalfans', 'l0y4lfans', 'l0y4lf4ns',
  '#loyalfans', '#loy4lfans', '#loyalf4ns', '#loy4lf4ns', '#l0yalfans', '#l0y4lfans', '#l0y4lf4ns',
  'vomit', 'v0mit', 'vom1t', 'v0m1t', 'vomi7', 'v0mi7', 'vom17', 'v0m17',
  'pornhub', 'p0rnhub',
  '#pornhub', '#p0rnhub',
  'manyvids', 'm4nyvids', 'm4nyv1ds', 'manyv1ds',
  '#manyvids', '#m4nyvids', '#m4nyv1ds', '#manyv1ds',
  'whatsapp', 'watsap', 'watsapp', 'whtsapp', 'whtsap',
  'bizum', 'bzum', 'bizm',
  'paypal', 'pypal', 'paypl',
  'telegram', 'telegrm', 'tlgrm', 'telgram', 'tlegram',
  'verse',
  'snapchat', 'sn4pchat', 'snapch4t', 'sn4pch4t',
  'tits2clicks', 'tits2clicks', 'tits2clicks,', 'tits2clicks.', 'tits2clicks-',
  'cashapp', 'c4shapp', 'cash4pp', 'c4sh4pp', 'cash@app', 'c@shapp', 'c@sh@pp', 'c4sh@pp', 'c@sh4app',

  'scat', 'heces', 'caca', 'popó', 'excremento', 'defecación', 'fecal', 'escatológico',
  'mierda', 'mojón', 'defecar', 'heces fecales', 'excreta', 'dejetos', 'popis', 'excreción',
  'truño', 'boñiga', 'cagada', 'pastelito', 'plasta', 'tronco', 'cagarro', 'zurrullo', 'estiércol',
  'feces', 'poop', 'poopy', 'crap', 'dung', 'shit', 'poo', 'stool', 'droppings', 'manure',
  'turd', 'doo-doo', 'number two', 'bm', 'dump', 'log', 'excrement', 'defecation',
  'farts', 'pooping', 'smearing', 'toilet', 'diapers', 'piss', 'enema',
  'mierd@', 'c4ca', 'p00p', 'sh1t', 'c@gada', 'c@g@', 'p0p0', 'h3c3s', 'f3cal', 'excrem3nto', 'cr@p',
  'caguita', 'truñito', 'pastelazo', 'mojonazo', 'zurrullito', 'plaston', 'plastita',
  'mierd4', 'c@ca', 'defecaci0n', 'escat0logico', 'pop0',
  'pañales', 'mear', 'pis'
]

const scatAllowed = [
  'scat', 'heces', 'caca', 'popó', 'excremento', 'defecación', 'fecal', 'escatológico',
  'mierda', 'mojón', 'defecar', 'heces fecales', 'excreta', 'dejetos', 'popis', 'excreción',
  'truño', 'boñiga', 'cagada', 'pastelito', 'plasta', 'tronco', 'cagarro', 'zurrullo', 'estiércol',
  'feces', 'poop', 'poopy', 'crap', 'dung', 'shit', 'poo', 'stool', 'droppings', 'manure',
  'turd', 'doo-doo', 'number two', 'bm', 'dump', 'log', 'excrement', 'defecation',
  'farts', 'pooping', 'smearing', 'toilet', 'diapers', 'piss', 'enema',
  'mierd@', 'c4ca', 'p00p', 'sh1t', 'c@gada', 'c@g@', 'p0p0', 'h3c3s', 'f3cal', 'excrem3nto', 'cr@p',
  'caguita', 'truñito', 'pastelazo', 'mojonazo', 'zurrullito', 'plaston', 'plastita',
  'mierd4', 'c@ca', 'defecaci0n', 'escat0logico', 'pop0',
  'pañales', 'mear', 'pis'
]

const EmailRegex = /.+@.+\..+/
const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
const AlphanumericRegex = /^[a-zA-Z0-9_]*$/
const IntegerRegex = /^[0-9]*$/
const PhoneRegex = /(\+34|0034|34)?[ -.,_]*([0-9][ -.,_]*){9,11}/
const LinkRegex = new RegExp(`(http(s?):\\/\\/)?(www.)?[a-zA-Z0-9]+\\.(${tlds})`, 'g')

const getCurrentUser = () => store.state.profile.currentUser

const isScat = () => config.project === 'scatbook' || getCurrentUser().scatProfile

const findBannedWords = (text) => {
  const splitted = text.toLowerCase().split(' ').map(word => word.replace(/[+!?,._-]/g, ''))
  let wordsBannedProject = wordsBanned

  if (isScat()) {
    wordsBannedProject = wordsBanned.filter(word => !scatAllowed.includes(word))
  }

  return splitted.filter(w => wordsBannedProject.includes(w)).join(', ')
}

const findPhoneNumber = (text) => {
  const words = text.split(' ')
  for (const word of words) {
    if (!word.includes('trials')) {
      if (PhoneRegex.test(word)) {
        return word
      }
    }
  }
}

const rules = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        $vOnlyAlphanumeric: (v) => !v || AlphanumericRegex.test(v) || i18n.t('errors.only_alphanumerics'),
        $vInteger: (v) => !v || IntegerRegex.test(v) || i18n.t('errors.only_integers'),
        $vPhone: (v) => {
          if (!v) return true
          const phone = findPhoneNumber(v)
          return !phone || i18n.t('errors.not_phone')
        },
        $vMin: (min) => (v) => v >= min || i18n.t('errors.min_value', {value: min}),
        $vMax: (max) => (v) => v <= max || i18n.t('errors.max_value', {value: max}),
        $vMaxCurrency: (max) => (v) => v <= max || i18n.t('errors.max_value', {value: max ? parseFloat(max.toString().replace(',', '.')) / 100 : null}),
        $vMinLength: (min, fieldName) => (v) => {
          const length = !!v && v.trim().length
          if (!length || (length >= min)) {
            return true
          }
          const field = fieldName || i18n.t('errors._default_length_field')
          return i18n.t('errors.no_min_length', {min, field})
        },
        $vMaxLength: (max) => (v) => {
          const length = !!v && v.trim().length
          if (!length || (length <= max)) {
            return true
          }
          return i18n.t('errors.no_max_length', {max})
        },

        $vMinMaxLength: (min, max, field) => (v) => {
          const length = !!v && v.trim().length
          if (!length || (length >= min && length <= max)) {
            return true
          }
          const key = field ? 'no_min_max_field_length' : 'no_min_max_length'
          return i18n.t(`errors.${key}`, {min, max, field})
        },
        $vBannedWords: (v) => {
          if (!v) return true
          const word = findBannedWords(v)
          return !word || i18n.t('errors.banned_word', {word})
        },
        $vRequired: (v) => {
          if ((typeof v) === 'string') return !!v.trim() || i18n.t('errors.required')
          return !!v || i18n.t('errors.required')
        },
        $vBoolRequired: (v) => (v) || i18n.t('errors.required'),
        $vNoHtml: (field) => {
          var doc = new DOMParser().parseFromString(field, 'text/html')
          return !Array.from(doc.body.childNodes).some(node => node.nodeType === 1) || i18n.t('errors.no_html_tags')
        },
        $vNoLink: (field) => {
          const splitted = field.toLowerCase().split(' ')
          const links = splitted.filter(w => w.match(LinkRegex))
          return links.filter((w) => {
            if (!w.includes('loverfans') && !w.includes('scatbook') && !w.includes('darkfans') && !w.includes('loverachelle2') && !w.includes('jordienp') && !w.includes('kaitlynkatsarosofficial')) {
              return w
            }
          }).join(', ')
        },
        $vValidNIF: (v) => v != null ? isValid(v.replace(/[^0-9a-zA-Z]/g, '')) || i18n.t('errors.invalidNif') : false,
        $vEmail: (v) => EmailRegex.test(v) || i18n.t('errors.bad_email'),
        $vNoWhiteSpace: (v) => !v.trim().includes(' ') || i18n.t('errors.no_white_spaces'),
        $vPassword: (v) => !v || PasswordRegex.test(v) || i18n.t('errors.bad_password'),
        $vConfirmation: function (field) {
          return (value) => {
            const ref = this.$refs[field]
            return !ref || ref.value === value || this.$t('errors.dont_match')
          }
        },
        $vIsOver18: function (field) {
          const [day, month, year] = field.split('/').map(Number);
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth() + 1;
          const currentDay = currentDate.getDate();

          const age = currentYear - year - (currentMonth < month || (currentMonth === month && currentDay < day) ? 1 : 0);

          return age >= 18 || i18n.t('errors.over_18');
        },
        $vAfterToday: function (field) {
          const parts = field.split('/')
          const today = new Date()
          const inputDate = new Date(parts[2], parts[0], parts[1])
          return inputDate > today || i18n.t('errors.over_18')
        }
      }
    })
  }
}

export function getBannedWords() {
  return wordsBanned
}

export function existBannedWords(text) {
  if (!text) return false
  return findBannedWords(text)
}

export function noHtml(field) {
  if (!field) return false
  var doc = new DOMParser().parseFromString(field, 'text/html')
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1)
}

export function noLink(text) {
  if (!text) return false
  const splitted = text.toLowerCase().split(' ')
  const links = splitted.filter(w => w.match(LinkRegex))
  return links.filter((w) => {
    if (!w.includes('loverfans') && !w.includes('scatbook') && !w.includes('darkfans') && !w.includes('loverachelle2') && !w.includes('jordienp') && !w.includes('kaitlynkatsarosofficial')) {
      return w
    }
  }).join(', ')
}

export function noPhone(field) {
  if (!field) return false
  return findPhoneNumber(field)
}

export default rules
