import store from '@/store'

const queryString = (query) => {
  return Object.keys(query)
    .map(key => `${key}=${encodeURIComponent(query[key])}`)
    .join('&')
}

export default (to, from, next) => {

  const skipRedirect = 'skipRedirect'
  const scatBaseUrl = 'https://scatbook.com'
  const currentQuery = {...to.query}

  const isScatProfile = store.state.profile.currentUser.scatProfile
  const authenticated = store.state.session.authenticated
  to.query.oa = store.state.profile.currentUser.sessionToken

  if (skipRedirect in currentQuery) {
    delete currentQuery[skipRedirect]
    /*next({
      path: to.path,
      query: currentQuery
    })*/
    return
  }
  if (isScatProfile && authenticated) {
    if (!window.location.href.startsWith(scatBaseUrl)) {
      window.location.href = `${scatBaseUrl}${to.path}?${queryString(to.query)}`
    }
  }
}
// localhost:8080/galleru?skipRedirect=true
